$(document).ready(function() {
  var map;
  var onlyDisplayOpenStore = false;
  var markers = [];
  var visibleMarkers = [];
  var myLocationMarker;
  var storeListWindowDiv;
  const calendarApiUrl = 'https://www.googleapis.com/calendar/v3/calendars/japanese__ja@holiday.calendar.google.com/events?';
  var isTodayHoliday = false;
  var isTommorowHoliday = false;
  var default_pos = { lat: 35.655391, lng: 139.757135 };
  var init_pos;

  var apiKey;
  var groupStores;
  var favorite_store_ids;
  var loggedIn;
  var logInGuidancePath;
  var addFavoritePath;
  var removeFavoritePath;
  var currentSelectedMarker;
  var center;
  var target_pos;
  var clusterer;

  // 単一店舗ピン用
  var defaultMarkerIcon;
  var selectedMarkerIcon;
  var favoriteMarkerIcon;
  var selectedFavoriteMarkerIcon;

  // 座標が同じ複数店舗の共同ピン用
  var compositeMarkerIcon;
  var selectedCompositeMarkerIcon;
  var favoriteCompositeMarkerIcon;
  var selectedFavoriteCompositeMarkerIcon;

  function getData() {
    apiKey = $('#map').data('api-key');
    groupStores = $('#map').data('group-stores');
    favorite_store_ids = $('#map').data('favorite-store-ids');
    loggedIn = $('#map').data('logged-in');
    logInGuidancePath = $('#map').data('log-in-guidance-path');
    addFavoritePath = $('#map').data('add-favorite-path');
    removeFavoritePath = $('#map').data('remove-favorite-path');
    target_pos = $('#map').data('target-pos');
    init_pos = target_pos ? target_pos : default_pos;
  }

  function initMap() {
    var styles = [
      {
         "featureType": "all",
         "elementType": "labels.icon",
         "stylers": [
           {
             "visibility": "off"
           }
         ]
       }
    ]

    map = new google.maps.Map(document.getElementById('map'), {
      zoom: 16,
      minZoom: 4,
      styles: styles,
      gestureHandling: 'greedy',
      disableDefaultUI: true
    });

    centerMapCurrentPosition(target_pos === undefined);
    setTimeout(function() {
      if (center == null) {
        map.setCenter(init_pos);
      }
    }, 3000);

    $('#current_location').on('click', (e) => {
      centerMapCurrentPosition();
    });

    google.maps.event.addListener(map, 'click', () => {
      closeStoreListWindow();
    });

    var storeDisplayControlDiv = document.getElementById("js-map-control");
    var storeDisplayControl = new StoreDisplayControl();
    storeDisplayControl.index = 1;
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(storeDisplayControlDiv);

    storeListWindowDiv = document.getElementById('js-map-stores-window');
    var storeListWindowControl = new StoreListWindow();
    storeListWindowControl.index = 1;
    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(storeListWindowDiv);

    var defaultIconScaledSize = new google.maps.Size(37, 52);
    defaultMarkerIcon = {
      url: "/map-icon-2.png",
      scaledSize: defaultIconScaledSize
    };
    selectedMarkerIcon = {
      url: "/map-icon-1.png",
      scaledSize: defaultIconScaledSize
    };
    favoriteMarkerIcon = {
      url: "/pin-favorite.png",
      scaledSize: defaultIconScaledSize
    };
    selectedFavoriteMarkerIcon = {
      url: "/pin-favorite-active.png",
      scaledSize: defaultIconScaledSize
    };

    var compositeIconScaledSize = new google.maps.Size(51, 56);
    var labelOrigin = new google.maps.Point(39, 11.5);
    compositeMarkerIcon = {
      url: "/pin-count.png",
      scaledSize: compositeIconScaledSize,
      labelOrigin: labelOrigin
    };
    selectedCompositeMarkerIcon = {
      url: "/pin-active-count.png",
      scaledSize: compositeIconScaledSize,
      labelOrigin: labelOrigin
    };
    favoriteCompositeMarkerIcon = {
      url: "/pin-favorite-count.png",
      scaledSize: compositeIconScaledSize,
      labelOrigin: labelOrigin
    };
    selectedFavoriteCompositeMarkerIcon = {
      url: "/pin-favorite-active-count.png",
      scaledSize: compositeIconScaledSize,
      labelOrigin: labelOrigin
    };
  }

  function handleLocationError(infoWindow, pos, message) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(`<p>${message}</p>`);
    infoWindow.open(map);
  }

  function centerMapCurrentPosition(no_target = true) {
    var infoWindow = new google.maps.InfoWindow;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        if (no_target) {
          map.setCenter(pos);
        } else {
          map.setCenter(init_pos);
        }
        center = pos;
        if (myLocationMarker == null) {
          myLocationMarker = new google.maps.Marker({
             position: pos,
             map: map,
             icon: {
               url: '/my-location-icon.png',
               scaledSize: new google.maps.Size(30, 30)
             }
           });
        } else  {
          myLocationMarker.setPosition(pos);
        }
        $('#current_location').addClass('is-touched');
      }, function(e) {
        if (no_target) { map.setCenter(init_pos); }
        $('#current_location').removeClass('is-touched');
        var errorMessage;
        switch(e.code) {
          case 1:
            errorMessage = '位置情報の許可がありません';
            break;
          case 2:
            errorMessage = '位置情報の取得に失敗しました';
            break;
          case 3:
            errorMessage = '情報の取得がタイムアウトしました';
            break;
        }
        handleLocationError(infoWindow, map.getCenter(), errorMessage);
      });
    } else {
      $('#current_location').removeClass('is-touched');
      handleLocationError(infoWindow, map.getCenter(), "位置情報が利用できません。");
    }
  }

  function addMarkers() {
    $('#js-map-stores-inner').hide();

    // 営業日トグルをタップした際に重複してmarkerがpushされるのを防ぐため配列を一旦クリア
    if (clusterer !== undefined) {
      clusterer.clearMarkers();
    }
    markers = [];

    var count;
    var contentHtml;
    var hasFavorite;
    $.each(groupStores, function(groupIndex, group) {
      contentHtml = "";
      count = 0;
      hasFavorite = false;
      group.map(function(store){
        if(onlyDisplayOpenStore && !isStoreOpen(store)) {
          return true;
        }
        contentHtml += storeHtml(store, groupIndex);
        count += 1;

        if(favorite_store_ids.includes(store.id)) {
          hasFavorite = true;
        }
      });
      const position = new google.maps.LatLng(group[0].latitude, group[0].longitude);
      if(count >= 1) {
        var marker = new google.maps.Marker({
          map: map,
          position: position,
          label: count > 1 ? { text: String(count),
                               color: '#ffffff',
                               fontFamily: 'sans-serif',
                               fontSize: '14px',
                               lineHeight: 1
                             } : '', // 店舗数表示
          icon: getInactiveMakeIcon(hasFavorite, count),
          hasFavorite: hasFavorite,
          count: count
        });
        marker['groupIndex'] = groupIndex;
        marker['listHtml'] = contentHtml;
        addMarkerClickListener(marker);
        markers.push(marker);
      }

      // 経緯度が同じ複数店舗のまとめマーカーの場合、総数-1個非表示マーカーを追加（MarkerClustererで集計数字が店舗数で出るため）
      if(count > 1) {
        addInvisableMarkersForMarkerClustererCount(count - 1, position);
      }
    });

    clusterer = new MarkerClusterer(map, markers, { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', maxZoom: 15, minimumClusterSize: 1 });

    const updateCluster = _.debounce(() => {
      const bounds = map.getBounds();
      clusterer.clearMarkers();
      visibleMarkers = markers.filter((marker) => {
        return marker.getVisible() && bounds && bounds.contains(marker.getPosition());
      });
      clusterer.addMarkers(visibleMarkers);
    }, 100);
    map.addListener('idle', updateCluster);
    updateCluster();
  }

  function addMarkerClickListener(marker) {
    google.maps.event.addListener(marker, 'click', function() {
      if (currentSelectedMarker == marker) {
        return;
      }

      marker.setIcon(getActiveMakeIcon(marker.hasFavorite, marker.count));
      if (currentSelectedMarker != null) {
        currentSelectedMarker.setIcon(getInactiveMakeIcon(currentSelectedMarker.hasFavorite, currentSelectedMarker.count));
      }
      currentSelectedMarker = marker;

      $('#js-map-stores-list').html(marker.listHtml);
      $('#js-map-stores-inner').show();
      $('#js-navigation').addClass('is-hidden');
      $('#js-map-stores-window').addClass('is-show');

      $('#usage_button').addClass('is-hidden');
    });
  }

  function isStoreOpen(store) {
    if (isTodayHoliday) {
      return store.holiday;
    }
    if (isTommorowHoliday) {
      return store.previous_holiday;
    }

    var dayOfWeek = new Date().getDay();
    switch (dayOfWeek) {
      case 0:
        return store.sunday;
      case 1:
        return store.monday;
      case 2:
        return store.tuesday;
      case 3:
        return store.wednesday;
      case 4:
        return store.thursday;
      case 5:
        return store.friday;
      case 6:
        return store.sunday;
    }
  }

  function storeHtml(store, groupIndex) {
    var favorite_class = favorite_store_ids.includes(store.id) ? 'is-added' : '';
    var storeHtml = `<div class="card border-0 bg-light HomeShow-storeItem">
                    <div class="card-body d-flex flex-column px-2 pt-3 pb-2">
                    <div class="px-2 flex-fill">
                    <h2 class="font-weight-bold HomeShow-storeItem__name">${store.name}</h2>
                    <div class="mt-2 HomeShow-storeItem__categoryList">
                    <span class="badge badge-dark px-2 py-1 HomeShow-storeItem__category">${store.genre_1}</span>
                    <span class="badge badge-dark px-2 py-1 HomeShow-storeItem__category">${store.genre_2 == null ? '' : store.genre_2}</span>
                    </div>
                    <a href="https://www.google.com/maps/search/?api=1&query=${store.address}" class="d-block mt-2 text-body HomeShow-storeItem__address">${store.address}</a>
                    ${[3912, 3923].includes(store.id) ? "" : `<a href="tel:${store.phone}" class="d-block mt-2 text-body HomeShow-storeItem__tel">${store.phone}</a>`}
                    <p class="mt-2 HomeShow-storeItem__open">営業時間：${store.sales_time_information}</p>
                    </div>
                    <div class="HomeShow-storeItem__actions">
                    <a href="/stores/${store.id}" class="d-block w-100 mt-2 c-button p-button--primary">
                    <span class="p-button__text HomeShow-action__button--storeShow">店舗詳細</span>
                    </a>
                    <button class="w-100 mt-2 c-button p-button--favorite ${favorite_class}" onclick="toggleFavorite(this);" data-store-id=${store.id} data-group-index=${groupIndex}>
                    <div class="p-button__default">
                    <i class="fas fa-heart p-button__icon"></i>
                    <span class="p-button__text">お気に入りに追加</span>
                    </div>
                    <div class="p-button__replacement">
                    <i class="fas fa-heart p-button__icon"></i>
                    <span class="p-button__text">お気に入り</span>
                    </div>
                     </button>
                   </div>
                   </div>
                   </div>`;
    return storeHtml;
  }

  function updateMarkerByGroupIndex(groupIndex) {
    var listHtml = '';
    var hasFavorite = false;
    const group = groupStores[groupIndex];
    const marker = visibleMarkers.find((visibleMarker) => {
      return visibleMarker.groupIndex == groupIndex
    });

    group.map(function(store){
      if(onlyDisplayOpenStore && !isStoreOpen(store)) {
        return true;
      }
      listHtml += storeHtml(store, groupIndex);

      if(favorite_store_ids.includes(store.id)){
        hasFavorite = true;
      }
    });
    marker.hasFavorite = hasFavorite;
    marker['listHtml'] = listHtml;
    marker.setIcon(getActiveMakeIcon(marker.hasFavorite, marker.count));
    addMarkerClickListener(marker);
  }

  function addInvisableMarkersForMarkerClustererCount(invisableMarkersCount, position) {
    while (invisableMarkersCount > 0) {
      var marker = new google.maps.Marker({
          map: map,
          position: position,
          opacity: 0
      });
      markers.push(marker);
      invisableMarkersCount--;
    }
  }

  function setIsTodayTomorrowHoliday() {
    const today = new Date();
    const todayString = today.toISOString().substring(0, 10);
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowString = tomorrow.toISOString().substring(0, 10);
    $.ajax({
      dataType: "json",
      url: calendarApiUrl,
      data: {
        key: apiKey,
        timeMin: `${todayString}T00:00:00Z`,
        timeMax: `${tomorrowString}T12:00:00Z`
      },
      async: false
    }).done(function(data) {
      $.each(data.items, function(index, item) {
        if (item.start.date == todayString) {
          isTodayHoliday = true;
        }else if (item.start.date == tomorrowString) {
          isTommorowHoliday = true;
        }
      });
    }).fail(function(error) {
      console.log(error);
      isTodayHoliday = false;
      isTommorowHoliday = false;
    });
  }

  function StoreDisplayControl() {
    var contolCheckbox = document.getElementById("js-map-display-toggle");

    contolCheckbox.addEventListener("click", function(e) {
      onlyDisplayOpenStore = $(this).prop("checked");
      if (onlyDisplayOpenStore) {
        $('.HomeShow-alert').addClass('show');
      } else {
        $('.HomeShow-alert').removeClass('show');
      }
      addMarkers();
    });

    var alertCloseButton = document.getElementById("alert_close_button");
    alertCloseButton.addEventListener("click", function(e) {
      $('.HomeShow-alert').removeClass('show');
    });
  }

  function StoreListWindow() {
    var closeButton = document.getElementById("js-map-stores-close-button");

    closeButton.addEventListener("click", () => {
      closeStoreListWindow();
    });
  }

  function closeStoreListWindow() {
    if (currentSelectedMarker == null) {
        return;
    }

    currentSelectedMarker.setIcon(getInactiveMakeIcon(currentSelectedMarker.hasFavorite, currentSelectedMarker.count));
    currentSelectedMarker = null;

    $('#js-map-stores-inner').hide();
    $('#js-navigation').removeClass('is-hidden');
    $('#js-map-stores-window').removeClass('is-show');

    $('#usage_button').removeClass('is-hidden');
  }

  this.toggleFavorite = function(obj) {
    if(!loggedIn) {
      location.href = logInGuidancePath;
      return;
    }
    const storeId = $(obj).data('store-id');
    const groupIndex = parseInt($(obj).data('group-index'));

    if ($(obj).hasClass('is-added')) {
      removeFavorite(storeId, groupIndex);
      $(obj).removeClass('is-added');
    } else {
      addFavorite(storeId, groupIndex);
      $(obj).addClass('is-added');
    }
  }

  function addFavorite(storeId, groupIndex) {
    $.ajax({
        url: addFavoritePath,
        type:'POST',
        dataType: 'json',
        data : { store_id: storeId },
        timeout:3000,
    }).done(function() {
      favorite_store_ids.push(storeId);
      updateMarkerByGroupIndex(groupIndex);
    }).fail(function(e) {
      alert('お気に入りを追加できませんでした。');
    })
  }

  function removeFavorite(storeId, groupIndex) {
    $.ajax({
        url: removeFavoritePath,
        type:'POST',
        dataType: 'json',
        data : { store_id: storeId },
        timeout:3000,
    }).done(function() {
      favorite_store_ids = $.grep(favorite_store_ids, function(value) {
        return value != storeId;
      });
      updateMarkerByGroupIndex(groupIndex);
    }).fail(function(e) {
      alert('お気に入りを解除できませんでした。');
    })
  }

  function getInactiveMakeIcon(hasFavorite, count) {
    if(count == 1) {
      return hasFavorite ? favoriteMarkerIcon : defaultMarkerIcon;
    } else if(count > 1) {
      return hasFavorite ? favoriteCompositeMarkerIcon : compositeMarkerIcon;
    }
  }

  function getActiveMakeIcon(hasFavorite, count) {
    if(count == 1) {
      return hasFavorite ? selectedFavoriteMarkerIcon : selectedMarkerIcon;
    } else if(count > 1) {
      return hasFavorite ? selectedFavoriteCompositeMarkerIcon : selectedCompositeMarkerIcon;
    }
  }

  if($('#map').length != 0){
    $("#js-map-waiting-modal").addClass('is-open');

    getData();
    setIsTodayTomorrowHoliday();
    initMap();
    google.maps.event.addListenerOnce(map, 'tilesloaded', addMarkers);
  }

  $('.js-map-waiting-modal-close').on('click', () => {
    $("#js-map-waiting-modal").removeClass('is-open');
  });
});
